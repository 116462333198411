export const STATE = {
  PENDING: 'pending',
  IN_PROGRESS: 'in_progress',
  READY: 'ready_for_pickup',
  ARCHIVED: 'archived',
  DROPPED_OFF: 'dropped_off',
};

export const STATE_PRIO = {
  [STATE.PENDING]: 10,
  [STATE.IN_PROGRESS]: 20,
  [STATE.READY]: 30,
  [STATE.ARCHIVED]: 40,
  [STATE.DROPPED_OFF]: 40,
};

export const FUTURE_FROM_SECONDS = 20 * 60;
