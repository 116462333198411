import { createSlice } from '@reduxjs/toolkit';

const NAME = 'optimisticDialog';

const initialState = {
  visible: false,
  entity: null,
  objectId: null,
};

const slice = createSlice({
  name: NAME,
  initialState,
  reducers: {
    showOptimisticDialog: (state, action) => {
      const { entity, objectId } = action.payload;
      state.entity = entity; state.objectId = objectId; state.visible = true;
    },
    hideOptimisticDialog: (state) => {
      state.entity = null;
      state.objectId = null;
      state.visible = false;
    },
  },
});

export const { showOptimisticDialog, hideOptimisticDialog } = slice.actions;

export const selectOptimisticDialogVisible = (state) => state[NAME].visible;
export const selectOptimisticDialogEntity = (state) => state[NAME].entity;
export const selectOptimisticDialogObjectId = (state) => state[NAME].objectId;

export default slice.reducer;
