import { createSlice } from '@reduxjs/toolkit';

const NAME = 'timer';

const calcNow = () => Math.floor(Date.now() / 1000);

const initialState = {
  now: calcNow(),
};

const slice = createSlice({
  name: NAME,
  initialState,
  reducers: {
    tick: (state) => {
      state.now = calcNow();
    },
  },
});

export const { tick } = slice.actions;
export const selectTimerNow = (state) => state[NAME].now;

export default slice.reducer;
