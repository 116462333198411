import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

const NAME = 'party';

const initialState = {
  loading: 'false',
  error: null,
  details: {},
};

export const createParty = createAsyncThunk(
  'party/create',
  async (payload, thunkAPI) => {
    try {
      return payload;
    }
    catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

const slice = createSlice({
  name: NAME,
  initialState,
  reducers: {
    setPartyDetailsEmpty: (state) => {
      state.details = {};
    },
  },
  extraReducers: {
    [createParty.pending]: (state) => {
      state.loading = true;
    },
    [createParty.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [createParty.fulfilled]: (state, action) => {
      const details = action.payload;
      state.details = details;
      state.loading = false;
      state.error = null;
    },
  },

});

export const selectParty = (state) => state[NAME].menu;
export const selectPartyLoading = (state) => state[NAME].loading;

export default slice.reducer;
