import { createSlice } from '@reduxjs/toolkit';

const NAME = 'sidebar';

const initialState = {
  isOpen: false,
};

const slice = createSlice({
  name: NAME,
  initialState,
  reducers: {
    setSidebarOpen: (state, action) => {
      state.isOpen = action.payload;
    },
  },
});

export const { setSidebarOpen } = slice.actions;
export const selectSidebarOpen = (state) => state[NAME].isOpen;

export default slice.reducer;
