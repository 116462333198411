import { buildGet, buildPost, buildPatch } from 'api/builders';

export const index = ({ locationId, dispatch }) => buildGet({
  url: `sign_up/api/menus?location_id=${locationId}`,
  dispatch,
});

export const update = ({ menuId, locationId, dispatch }) => buildPatch({
  url: `sign_up/api/menus/${menuId}?location_id=${locationId}`,
  dispatch,
});

export const pause = ({ locationId, dispatch }) => buildPost({
  url: `sign_up/api/menus/pause?location_id=${locationId}`,
  dispatch,
});
