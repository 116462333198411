import {
  addInterval,
  convertToZoned,
  formatDate,
} from 'utils/date';

export const smartRound = (value) => {
  const result = value < 0 ? Math.floor(value) : Math.ceil(value);
  return result;
};

export const preparationLeftTime = ({
  acceptedAt,
  asapInterval,
  autoAccept,
  fulfillmentAt: fat,
  now,
}) => {
  if (autoAccept) {
    const dateFulfilledAt = new Date(fat).getTime() / 1000;
    const diff = dateFulfilledAt - now;
    const diffMin = smartRound(diff / 60);
    return diffMin;
  }
  const dateAcceptedAt = new Date(acceptedAt).getTime() / 1000;
  const fulfilledAtCalc = dateAcceptedAt + asapInterval * 60;
  const diff = fulfilledAtCalc - now;

  // Diff cannot be more than asapInterval
  const diffMin = Math.min(asapInterval, smartRound(diff / 60));
  return diffMin;
};

export const preparationStatus = (asapInterval, acceptedAt, now) => {
  const dateAcceptedAt = new Date(acceptedAt).getTime() / 1000;
  const fulfilledAtCalc = dateAcceptedAt + asapInterval * 60;
  if ((now - fulfilledAtCalc) < 0) {
    return 'warning';
  }
  return 'error';
};

export const pickupTime = ({
  acceptedAt,
  asapInterval,
  autoAccept,
  fulfillmentAt,
  timezone,
}) => {
  if (autoAccept) {
    const fulfillmentAtTz = convertToZoned(new Date(fulfillmentAt), timezone);
    return formatDate(fulfillmentAtTz);
  }
  return addInterval(acceptedAt, asapInterval);
};

/**
 *
 * Check delivery/pickup. Use fulfillmentAt in case of pickup
 * Use estimatedPickupTime in case of delviery
 *
 * */

export const isFutureOrder = (order) => {
  const { fulfillment, externalDelivery, orderFulfillmentDelivery } = order || {};
  const {
    fulfillmentAt, asapInterval = 20, kind, scheduleType = '',
  } = fulfillment || {};
  if (scheduleType === 'asap') {
    return false; // No move to future in ASAP case
  }
  let readyAt = fulfillmentAt;
  if (kind === 'delivery') {
    if (externalDelivery) {
      const { estimatedPickupTime } = externalDelivery;
      readyAt = estimatedPickupTime;
    }
    else if (orderFulfillmentDelivery) {
      const { driverPickupAt } = orderFulfillmentDelivery;
      readyAt = driverPickupAt;
    }
  }
  const readyAtMillis = new Date(readyAt).getTime();
  const futureDiff = readyAtMillis - Date.now();
  const futureDiffSec = futureDiff / 1000;
  return futureDiffSec >= asapInterval * 60;
};
