import { createSlice, isPending, isRejected } from '@reduxjs/toolkit';
import {
  WAITLIST_PARTIES_QUERY,
  WAITLIST_PARTY_CREATE,
  WAITLIST_PARTY_UPDATE,
  WAITLIST_CANCEL_PARTY,
  WAITLIST_SEAT_AT_PARTY,
  WAITLIST_PARTY_NEXT_UP,
  WAITLIST_PARTY_READY,
} from 'api/queries';
import { buildGqlQueryAction, buildGqlMutationAction } from 'slices/builders';

const NAME = 'waitlists';

const initialState = {
  loading: false,
  error: null,
  waitlists: [],
  selectedId: null,
};

export const waitlistsNotifyReady = buildGqlMutationAction({
  actionName: 'waitlists/notifyReady',
  query: WAITLIST_PARTY_READY,
  variables: (payload) => ({ input: { id: payload } }),
  onResult: ({ dispatch }) => {
    dispatch(waitlistsFetch());
  },
});

export const waitlistsNotifyNextup = buildGqlMutationAction({
  actionName: 'waitlists/notifyNextup',
  query: WAITLIST_PARTY_NEXT_UP,
  variables: (payload) => ({ input: { id: payload } }),
  onResult: ({ dispatch }) => {
    dispatch(waitlistsFetch());
  },
});

export const waitlistsSeat = buildGqlMutationAction({
  actionName: 'waitlists/seat',
  query: WAITLIST_SEAT_AT_PARTY,
  variables: (payload) => ({ input: { id: payload } }),
  onResult: ({ dispatch }) => {
    dispatch(waitlistsFetch());
  },
});

export const waitlistsCancel = buildGqlMutationAction({
  actionName: 'waitlists/cancel',
  query: WAITLIST_CANCEL_PARTY,
  variables: (payload) => ({ input: { id: payload } }),
  onResult: ({ dispatch }) => {
    dispatch(waitlistsFetch());
  },
});

export const waitlistsCreate = buildGqlMutationAction({
  actionName: 'waitlists/create',
  query: WAITLIST_PARTY_CREATE,
  variables: (payload) => ({ input: payload }),
  onResult: ({ dispatch }) => {
    dispatch(waitlistsFetch());
  },
});

export const waitlistsUpdate = buildGqlMutationAction({
  actionName: 'waitlists/update',
  query: WAITLIST_PARTY_UPDATE,
  variables: (payload) => ({ input: payload }),
  onResult: ({ dispatch }) => {
    dispatch(waitlistsFetch());
  },
});

export const waitlistsFetch = buildGqlQueryAction({
  actionName: 'waitlists/fetch',
  query: WAITLIST_PARTIES_QUERY,
  variables: (payload = {}) => {
    const { past = false } = payload;
    return { past };
  },
  onResult: ({ result }) => {
    const { data = {} } = result;
    const { parties = [] } = data;
    return parties;
  },
});

const isMutationPending = isPending(waitlistsCreate, waitlistsCancel, waitlistsSeat, waitlistsNotifyNextup, waitlistsNotifyReady);
const isMutationRejected = isRejected(waitlistsCreate, waitlistsCancel, waitlistsSeat, waitlistsNotifyNextup, waitlistsNotifyReady);

const slice = createSlice({
  name: NAME,
  initialState,
  reducers: {
    setWaitlistsSelectedId: (state, action) => {
      state.selectedId = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(waitlistsFetch.fulfilled, (state, action) => {
        const waitlists = action.payload;
        state.waitlists = waitlists;
        state.loading = false;
        state.error = null;
      })
      .addCase(waitlistsFetch.pending, (state, action) => {
        const { meta = {} } = action;
        const { arg = {} } = meta;
        const { silent = false } = arg;
        if (!silent) {
          state.loading = true;
        }
      })
      .addCase(waitlistsFetch.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addMatcher(isMutationPending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addMatcher(isMutationRejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      });
  },

});

export const { setWaitlistsSelectedId } = slice.actions;

export const selectWaitlistsDomain = (state) => state[NAME];

export const selectWaitlists = (state) => selectWaitlistsDomain(state).waitlists;
export const selectWaitlistsLoading = (state) => selectWaitlistsDomain(state).loading;
export const selectWaitlistsSelectedId = (state) => selectWaitlistsDomain(state).selectedId;
export const selectWaitlistsSelected = (state) => {
  const { selectedId } = state[NAME];
  const all = state[NAME].waitlists;
  const waitlist = all.find((el) => el.id === selectedId);
  return waitlist;
};

export default slice.reducer;
