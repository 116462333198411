import { useEffect, useState } from 'react';
import Head from 'next/head';
// import Script from 'next/script';
// import { useRouter } from 'next/router';
import DimensionObserver from 'components/DimensionObserver';
import { Provider as RollbarProvider } from '@rollbar/react';
import dynamic from 'next/dynamic';
import { wrapper } from 'app/store';
import { Provider as GqlProvider } from 'urql';
import gqlClient from 'api/urqlClient';

let swReg = null;
let refreshing = false;
let state;

const NewVersionDialog = dynamic(() => import('components/NewVersionDialog'));

const MyApp = ({ Component, pageProps }) => {
  const [isRefreshDialog, setRefreshDialog] = useState(false);
  // const router = useRouter();
  // const { query = {} } = router;
  // const { eruda = false } = query;

  const promptUserToRefresh = () => {
    setRefreshDialog(true);
  };

  const listenForWaitingServiceWorker = (callback) => {
    const awaitStateChange = function () {
      swReg.installing.addEventListener('statechange', () => {
        if (state === 'installed') {
          callback();
        }
      });
    };

    if (swReg) {
      if (swReg.waiting) {
        return callback();
      }
      if (swReg.installing) {
        awaitStateChange();
      }
      swReg.addEventListener('updatefound', awaitStateChange);
    }
    return false;
  };

  const getRegistration = async () => {
    const sw = navigator.serviceWorker;
    swReg = await sw.getRegistration();
    listenForWaitingServiceWorker(promptUserToRefresh);
  };

  const handleVersionRefresh = () => {
    if (swReg) {
      swReg.waiting.postMessage('skipWaiting');
    }
  };

  const rollbarConfig = {
    accessToken: process.env.NEXT_PUBLIC_ROLLBAR_CLIENT_TOKEN,
    payload: { environment: process.env.NEXT_PUBLIC_ROLLBAR_ENV },
  };

  useEffect(() => {
    if ('serviceWorker' in navigator) {
      const sw = navigator.serviceWorker;
      getRegistration();
      sw.addEventListener('controllerchange', () => {
        if (refreshing) return;
        refreshing = true;
        window.location.reload();
      });
    }
  }, []);

  // const erudaScriptRun = eruda === 'true' ? (
  //  <Script strategy="afterInteractive">
  //    eruda.init();
  //  </Script>
  // ) : null;

  return (
    <>
      {/*
      <Script strategy="beforeInteractive" src="//cdn.jsdelivr.net/npm/eruda" />
      {erudaScriptRun}
      */}
      <Head>
        <title>Lightning Dashboard</title>
      </Head>
      <RollbarProvider config={rollbarConfig}>
        <DimensionObserver>
          <GqlProvider value={gqlClient}>
            <Component {...pageProps} />
          </GqlProvider>
        </DimensionObserver>
      </RollbarProvider>
      <NewVersionDialog
        visible={isRefreshDialog}
        onClose={() => setRefreshDialog(false)}
        onRefresh={handleVersionRefresh}
      />
    </>
  );
};

export default wrapper.withRedux(MyApp);
