import { getValidToken, getTokenInfo, isStateValid } from 'utils/token';
import { authExchange } from '@urql/exchange-auth';
import {
  createClient, dedupExchange, cacheExchange, fetchExchange,
} from 'urql';
import { makeOperation } from '@urql/core';

const { NEXT_PUBLIC_SCVR_ROOT } = process.env;

const getAuth = async () => {
  await getValidToken();
  const { token, refreshToken, expiresAt } = getTokenInfo();
  console.log('getAuth', token);
  return { token, refreshToken, expiresAt };
};

const addAuthToOperation = ({ authState, operation }) => {
  console.log('addAuthToOperation', authState);
  // the token isn't in the auth state, return the operation without changes
  if (!authState || !authState.token) {
    return operation;
  }

  // fetchOptions can be a function (See Client API) but you can simplify this based on usage
  const fetchOptions = typeof operation.context.fetchOptions === 'function'
    ? operation.context.fetchOptions()
    : operation.context.fetchOptions || {};

  const op = makeOperation(operation.kind, operation, {
    ...operation.context,
    fetchOptions: {
      ...fetchOptions,
      headers: {
        ...fetchOptions.headers,
        Authorization: `Bearer ${authState.token}`,
      },
    },
  });

  return op;
};

const willAuthError = ({ authState }) => {
  const isValid = isStateValid(authState);
  console.log('willAuthError', authState, isValid);
  if (!isValid) return true;
  return false;
};

const client = createClient({
  url: `${NEXT_PUBLIC_SCVR_ROOT}/graphql`,
  requestPolicy: 'network-only',
  exchanges: [
    dedupExchange,
    cacheExchange,
    authExchange({
      getAuth,
      addAuthToOperation,
      willAuthError,
    }),
    fetchExchange,
  ],
});

export default client;
