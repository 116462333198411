import {
  createAsyncThunk, createSlice, current,
} from '@reduxjs/toolkit';
import { show, preparationStatusUpdate, createDelivery } from 'api/orders';
import { selectCurrentLocationId } from 'slices/account';
import { fetchOrders } from 'slices/orders';
import { showOptimisticDialog } from 'slices/optimisticDialog';
import { STATE as ORDER_STATE } from 'constants/orders';

const NAME = 'order';

const initialState = {
  loading: 'idle',
  error: null,
  order: {},
};

export const setOrderCreateDelivery = createAsyncThunk(
  'order/createDelivery',
  async (payload, thunkAPI) => {
    const { dispatch, getState } = thunkAPI;
    try {
      const locId = selectCurrentLocationId(getState());
      const updated = await createDelivery({ id: payload, locationId: locId, dispatch })({ id: payload });
      return updated;
    }
    catch (err) {
      return thunkAPI.rejectWithValue(err.message);
    }
  }
);

export const setOrderPreparationStatus = createAsyncThunk(
  'order/updatePreparationStatus',
  async (payload, thunkAPI) => {
    const { dispatch, getState } = thunkAPI;
    const currentOrder = selectOrderDetails(getState());
    const { id, /* forceDefault, */ ...bodyAttrs } = payload;
    try {
      const locId = selectCurrentLocationId(getState());
      const updated = await preparationStatusUpdate({ id, locationId: locId, dispatch })({ order: bodyAttrs });
      return updated;
    }
    catch (err) {
      const { id: currentId } = currentOrder;
      // show dialog
      dispatch(showOptimisticDialog({ entity: 'order', objectId: id }));
      // update current order if it is open
      if (currentId === id) {
        dispatch(fetchOrder({ id }));
      }
      // update order list
      dispatch(fetchOrders({ withDialog: false, silent: false, forceDefault: false }));
      return thunkAPI.rejectWithValue(err.message);
    }
  }
);

export const fetchOrder = createAsyncThunk(
  'order/fetch',
  async ({ id }, thunkAPI) => {
    const { dispatch, getState } = thunkAPI;
    try {
      const locId = selectCurrentLocationId(getState());
      const order = await show({ id, locationId: locId, dispatch });
      return order;
    }
    catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

const slice = createSlice({
  name: NAME,
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(setOrderPreparationStatus.pending, (state, action) => {
        // Optimistic order update
        const { meta = {} } = action;
        const { arg = {} } = meta;
        const { id, asap_interval: asapInterval, preparation_status: preparationStatus } = arg;
        const currState = current(state);
        const { order: currOrder = {} } = currState;
        const { id: currOrderId, fulfillment = {} } = currOrder;
        if (id === currOrderId) {
          let nFulfillment = fulfillment;
          if (preparationStatus === ORDER_STATE.IN_PROGRESS) {
            nFulfillment = { ...fulfillment, asapInterval };
          }
          const nOrder = { ...currOrder, preparationStatus, fulfillment: nFulfillment };
          state.order = nOrder;
        }
      })
      .addCase(setOrderPreparationStatus.fulfilled, (state, action) => {
        // Set details loaded from net
        const { payload } = action;
        const currState = current(state);
        const { order: currOrder = {} } = currState;
        const { id: currOrderId } = currOrder;
        if (currOrderId === payload.id) {
          state.order = payload;
        }
      })
      .addCase(setOrderPreparationStatus.rejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(fetchOrder.fulfilled, (state, action) => {
        state.order = action.payload;
        state.loading = 'idle';
        state.error = null;
      })
      .addCase(fetchOrder.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(fetchOrder.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = 'idle';
      });
  },

});

export const { updateOrder } = slice.actions;

export const selectOrderDetails = (state) => state[NAME].order;
export const selectOrderLoading = (state) => state[NAME].loading;

export default slice.reducer;
