import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Rollbar from 'rollbar';
import { index } from 'api/orders';
import {
  selectCurrentLocationId, selectCurrentLocation, selectLocations, fetchOrder,
} from 'slices';
import {
  processArray as splitOrders,
} from 'slices/orders/orderSplitter';

const rollbarConfig = {
  accessToken: process.env.NEXT_PUBLIC_ROLLBAR_CLIENT_TOKEN,
  payload: { environment: process.env.NEXT_PUBLIC_ROLLBAR_ENV },
};
const rollbar = new Rollbar(rollbarConfig);

const NAME = 'orders';

const initialState = {
  loading: 'idle',
  error: null,
  futureOrders: [],
  newOrders: [],
  progressOrders: [],
  readyOrders: [],
  archivedOrders: [],
  dialog: false,
  deliveryErrorDialogId: -1,
  selectedOrder: -1,
};

export const setSelectedOrderId = createAsyncThunk(
  'orders/setSelectedOrderId',
  async (id, thunkAPI) => {
    const { dispatch } = thunkAPI;
    // load order details. check silent/nosilent
    dispatch(fetchOrder({ id }));
    return id;
  }
);

export const setOrdersDialog = createAsyncThunk(
  'orders/setOrdersDialog',
  async (payload, thunkAPI) => {
    const { dispatch, getState } = thunkAPI;
    // select oldest order
    const newOrders = selectOrdersNew(getState());
    const oldestNewOrder = newOrders.slice(-1)[0];
    const { id: oldestId } = oldestNewOrder;
    dispatch(setSelectedOrderId(oldestId));
    const locId = selectCurrentLocationId(getState());
    // rollbar.info('orders/setOrdersDialog', { locId, dialog: payload });
    return payload;
  }
);

export const fetchOrders = createAsyncThunk(
  'orders/fetch',
  async ({ withDialog, silent, forceDefault }, thunkAPI) => {
    try {
      const { dispatch, getState } = thunkAPI;
      const locId = selectCurrentLocationId(getState());

      const allLocs = selectLocations(getState());
      const currentLocation = allLocs.find((loc) => loc.id === locId);
      const { timezone } = currentLocation;

      const selectedOrder = selectOrdersSelectedOrderId(getState());

      const orders = await index({ locationId: locId, dispatch })();
      const [futureOrders, newOrders, progressOrders, readyOrders, archivedOrders] = splitOrders(orders, timezone);

      if (selectedOrder < 0 || forceDefault) {
        const defaultOrder = newOrders[0] || progressOrders[0] || readyOrders[0] || { id: -1 };
        const { id: defaultOrderId } = defaultOrder;
        dispatch(setSelectedOrderId(defaultOrderId));
      }

      const state = getState();
      const currentNewOrders = selectOrdersNew(state);
      const newCount = Math.max((newOrders.length - currentNewOrders.length), 0);
      // rollbar.info('orders/fetch', {
      //   locId, newOrderCount: newOrders.length, newCount, withDialog,
      // });
      return {
        futureOrders, newOrders, progressOrders, readyOrders, archivedOrders, withDialog, silent,
      };
    }
    catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const checkFutureOrders = createAsyncThunk(
  'orders/checkFuture',
  async (_, thunkAPI) => {
    const { getState } = thunkAPI;

    const state = getState();
    const currentFutureOrders = selectOrdersFuture(state);
    const currentNewOrders = selectOrdersNew(state);
    const currentLocation = selectCurrentLocation(state);
    const { timezone } = currentLocation;

    const [futureOrders, newOrders] = splitOrders(currentFutureOrders, timezone);
    const nNewOrders = [...newOrders, ...currentNewOrders];
    const newCount = Math.max((nNewOrders.length - currentNewOrders.length), 0);
    const locId = selectCurrentLocationId(getState());
    // rollbar.info('orders/checkFuture', { locId, 'state.dialog': state.dialog, newCount });
    return {
      dialog: state.dialog ? state.dialog : newCount > 0,
      futureOrders,
      newOrders: nNewOrders,
    };
  }
);

const slice = createSlice({
  name: NAME,
  initialState,
  reducers: {
    setOrdersFuture: (state, action) => {
      state.futureOrders = action.payload;
    },
    setOrdersNew: (state, action) => {
      state.newOrders = action.payload;
    },
    setOrdersProgress: (state, action) => {
      state.progressOrders = action.payload;
    },
    setOrdersReady: (state, action) => {
      state.readyOrders = action.payload;
    },
    setOrdersArchived: (state, action) => {
      state.archivedOrders = action.payload;
    },
    setOrdersDeliveryErrorDialog: (state, action) => {
      state.deliveryErrorDialogId = action.payload;
    },
  },
  extraReducers: {
    [checkFutureOrders.fulfilled]: (state, action) => {
      const { payload = {} } = action;
      const { newOrders, futureOrders, dialog } = payload;
      state.dialog = dialog;
      state.newOrders = newOrders;
      state.futureOrders = futureOrders;
    },
    [setOrdersDialog.fulfilled]: (state, action) => {
      state.dialog = action.payload;
    },
    [setSelectedOrderId.fulfilled]: (state, action) => {
      state.selectedOrder = action.payload;
    },
    [fetchOrders.fulfilled]: (state, action) => {
      const {
        futureOrders,
        newOrders,
        progressOrders,
        readyOrders,
        archivedOrders,
        withDialog,
      } = action.payload;

      // split orders and set to state
      const newCount = Math.max((newOrders.length - state.newOrders.length), 0);

      state.futureOrders = futureOrders;
      state.newOrders = newOrders;
      state.progressOrders = progressOrders;
      state.readyOrders = readyOrders;
      state.archivedOrders = archivedOrders;

      if (!state.dialog) {
        state.dialog = (newCount > 0 && withDialog);
      }

      state.loading = 'idle';
      state.error = null;
    },
    [fetchOrders.pending]: (state, action) => {
      const { meta = {} } = action;
      const { arg = {} } = meta;
      const { silent = false } = arg;
      if (!silent) {
        state.loading = 'pending';
      }
    },
    [fetchOrders.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = 'idle';
    },
  },

});

export const {
  setOrdersFuture,
  setOrdersNew,
  setOrdersProgress,
  setOrdersReady,
  setOrdersArchived,
  setOrdersDeliveryErrorDialog,
} = slice.actions;

export const selectOrdersDomain = (state) => state[NAME];
export const selectOrdersSelectedOrderId = (state) => state[NAME].selectedOrder;
export const selectOrdersFuture = (state) => state[NAME].futureOrders;
export const selectOrdersNew = (state) => state[NAME].newOrders;
export const selectOrdersProgress = (state) => state[NAME].progressOrders;
export const selectOrdersReady = (state) => state[NAME].readyOrders;
export const selectOrdersArchived = (state) => state[NAME].archivedOrders;
export const selectOrdersDialog = (state) => state[NAME].dialog;
export const selectOrdersDeliveryErrorDialog = (state) => state[NAME].deliveryErrorDialogId;
export const selectOrdersLoading = (state) => state[NAME].loading;
export const selectOrdersActiveCount = (state) => {
  const newOrders = selectOrdersNew(state);
  const progressOrders = selectOrdersProgress(state);
  return newOrders.length + progressOrders.length;
};

export default slice.reducer;
