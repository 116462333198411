import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { update } from 'api/nodes';
import { selectCurrentLocationId } from 'slices/account';
import { fetchMenus } from 'slices/menus';

const NAME = 'nodes';

const initialState = {
  loading: false,
  error: null,
  nodes: [],
};

export const updateNode = createAsyncThunk(
  'nodes/update',
  async (payload, thunkAPI) => {
    try {
      const { id, menuId } = payload;
      const { dispatch, getState } = thunkAPI;
      const locId = selectCurrentLocationId(getState());
      const node = await update({
        nodeId: id, menuId, locationId: locId, dispatch,
      })(payload);
      await dispatch(fetchMenus());
      return node;
    }
    catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

const slice = createSlice({
  name: NAME,
  initialState,
  extraReducers: {
    [updateNode.pending]: (state) => {
      state.loading = true;
    },
    [updateNode.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [updateNode.fulfilled]: (state) => {
      state.loading = false;
      state.error = null;
    },
  },

});

export const selectNodesLoading = (state) => state[NAME].loading;

export default slice.reducer;
