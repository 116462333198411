import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  topOffset: null,
};

const slice = createSlice({
  name: 'misc',
  initialState,
  reducers: {
    setTopOffset: (state, action) => {
      state.topOffset = action.payload;
    },
  },
});

export const { setTopOffset } = slice.actions;

export const selectTopOffset = (state) => state.misc.topOffset;

export default slice.reducer;
