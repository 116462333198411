import {
  storage, isTokenValid, getTokenInfo,
} from 'utils/token';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { buildPost } from 'api/builders';

const NAME = 'auth';

const initialState = {
  loading: false,
  error: null,
  authorized: false,
};

export const freshToken = createAsyncThunk(
  'auth/freshToken',
  async (_, thunkAPI) => {
    const { dispatch } = thunkAPI;
    const isValid = isTokenValid();
    if (!isValid) {
      const { refreshToken } = getTokenInfo();
      if (refreshToken) {
        const response = await buildPost({ url: `oauth/token?grant_type=refresh_token&refresh_token=${refreshToken}`, refreshToken: true })('', async (response) => response);
        if (response.status < 400) {
          const json = await response.json();
          return {
            ...json, status: response.status, isValid: true, authorized: true, dispatch,
          };
        }
        return { authorized: false };
      }
      return { authrorized: false };
    }
    return { authorized: true, keepToken: true };
  }
);

export const validateToken = createAsyncThunk(
  'auth/validateToken',
  async (_, thunkAPI) => {
    try {
      const isValid = isTokenValid();
      if (!isValid) {
        const { refreshToken } = getTokenInfo();
        if (refreshToken) {
          await buildPost({ url: `oauth/token?grant_type=refresh_token&refresh_token=${refreshToken}`, refreshToken: true })('', async (response) => response);
        }
      }
      return isValid;
    }
    catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const authLogin = createAsyncThunk(
  'auth/login',
  async (payload, thunkAPI) => {
    const { dispatch } = thunkAPI;
    try {
      const { email, password } = payload;
      const formData = new FormData();
      formData.append('grant_type', 'password');
      formData.append('email', email);
      formData.append('password', password);
      const response = await buildPost({
        url: 'oauth/token',
        formData: true,
        preventRefresh: true,
      })(formData, async (response) => response);
      if (response.status < 400) {
        const json = await response.json();
        return { ...json, status: response.status, dispatch };
      }
      return { status: response.status };
    }
    catch (err) {
      return thunkAPI.rejectWithValue(err.message);
    }
  }
);

const slice = createSlice({
  name: NAME,
  initialState,
  reducers: {
    setAuthorized: (state, action) => {
      state.authorized = action.payload;
      if (!action.payload) {
        storage().removeItem('token');
        storage().removeItem('refreshToken');
        storage().removeItem('tokenExpiresAt');
      }
    },
  },
  extraReducers: {

    [freshToken.fulfilled]: (state, action) => {
      const {
        authorized,
        created_at: createdAt,
        expires_in: expiresIn,
        refresh_token: refreshToken,
        token,
        keepToken,
      } = action.payload;
      if (!keepToken) {
        const expiresAt = new Date(createdAt);
        expiresAt.setSeconds(expiresAt.getSeconds() + expiresIn);
        storage().setItem('token', token);
        storage().setItem('refreshToken', refreshToken);
        storage().setItem('tokenExpiresAt', expiresAt.getTime());
      }
      state.authorized = authorized;
    },
    [freshToken.rejected]: () => {},

    [authLogin.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [authLogin.fulfilled]: (state, action) => {
      state.loading = false;
      const {
        token,
        refresh_token: refreshToken,
        created_at: createdAt,
        expires_in: expiresIn,
        status,
      } = action.payload;
      if (status === 200) {
        const expiresAt = new Date(createdAt);
        expiresAt.setSeconds(expiresAt.getSeconds() + expiresIn);
        storage().setItem('token', token);
        storage().setItem('refreshToken', refreshToken);
        storage().setItem('tokenExpiresAt', expiresAt.getTime());
        state.authorized = true;
      }
      else {
        state.authorized = false;
      }
    },
    [authLogin.rejected]: (state, action) => {
      state.authorized = false;
      state.loading = false;
      state.error = action.payload;
    },

    [validateToken.pending]: (state) => {
      state.loading = true;
    },
    [validateToken.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [validateToken.fulfilled]: (state, action) => {
      const isValid = action.payload;
      state.authorized = isValid;
      state.loading = false;
      state.error = null;
    },
  },
});

export const { setAuthorized } = slice.actions;

export const selectAuth = (state) => state[NAME];
export const selectAuthorized = (state) => state[NAME].authorized;
export const selectAuthLoading = (state) => state[NAME].loading;

export default slice.reducer;
