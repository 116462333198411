import { createSlice } from '@reduxjs/toolkit';

const NAME = 'waitlistTimeDialog';

const initialState = {
  visible: false,
};

const slice = createSlice({
  name: NAME,
  initialState,
  reducers: {
    showWaitlistTimeDialog: (state) => {
      state.visible = true;
    },
    hideWaitlistTimeDialog: (state) => {
      state.visible = false;
    },
  },
});

export const { showWaitlistTimeDialog, hideWaitlistTimeDialog } = slice.actions;

export const selectWaitlistTimeDialogVisible = (state) => state[NAME].visible;

export default slice.reducer;
