import { STATE as ORDER_STATE } from 'constants/orders';
import { convertToZoned } from 'utils/date';
import { isFutureOrder } from 'utils/orders';

const splitFutureAndActive = (orders, timezone) => {
  const [unsortNewOrders, unsortFutureOrders] = orders.reduce((prev, curr) => {
    const [noAction, future] = prev;
    const { fulfillment } = curr;
    const { fulfillmentAt } = fulfillment;
    const fulfillmentAtTz = convertToZoned(new Date(fulfillmentAt), timezone);

    const now = Date.now();
    const nowTz = convertToZoned(now, timezone);
    nowTz.setHours(23, 59, 59);
    const isOrderTodayOrEarlier = fulfillmentAtTz <= nowTz;
    if (isOrderTodayOrEarlier) {
      if (isFutureOrder(curr)) {
        const order = { ...curr, isFuture: true };
        const nFuture = [...future, order];
        return [noAction, nFuture];
      }
      const nNoAction = [...noAction, curr];
      return [nNoAction, future];
    }
    return prev;
  }, [[], []]);

  const futureOrders = unsortFutureOrders.sort((i, j) => {
    const { fulfillment: ifull = {} } = i;
    const { fulfillment: jfull = {} } = j;
    const { fulfillmentAt: iFat } = ifull;
    const { fulfillmentAt: jFat } = jfull;
    const iDate = (new Date(iFat)).getTime();
    const jDate = (new Date(jFat)).getTime();
    return iDate - jDate;
  });

  const newOrders = unsortNewOrders.sort((i, j) => {
    const { fulfillment: ifull = {} } = i;
    const { fulfillment: jfull = {} } = j;
    const { fulfillmentAt: iFat } = ifull;
    const { fulfillmentAt: jFat } = jfull;
    const iDate = (new Date(iFat)).getTime();
    const jDate = (new Date(jFat)).getTime();
    return iDate - jDate;
  });

  return [newOrders, futureOrders];
};

export const processArray = (newAllOrders, timezone) => {
  const pendingOrders = newAllOrders.filter((order) => {
    const { preparationStatus = ORDER_STATE.PENDING, fulfillmentStatus } = order;
    return (preparationStatus === ORDER_STATE.PENDING || preparationStatus === null) && fulfillmentStatus !== 'fulfilled';
  });

  const [newOrders, futureOrders] = splitFutureAndActive(pendingOrders, timezone);

  const unsortNewProgressOrders = newAllOrders.filter((order) => {
    const { preparationStatus } = order;
    return preparationStatus === ORDER_STATE.IN_PROGRESS;
  });

  const progressOrders = unsortNewProgressOrders.sort((i, j) => {
    const { fulfillment: ifull = {} } = i;
    const { fulfillment: jfull = {} } = j;
    const { fulfillmentAt: iFat } = ifull;
    const { fulfillmentAt: jFat } = jfull;
    const iDate = (new Date(iFat)).getTime();
    const jDate = (new Date(jFat)).getTime();
    return iDate - jDate;

    // const { acceptedAt: iAccepted } = ifull;
    // const { acceptedAt: jAccepted } = jfull;
    // const iDate = (new Date(iAccepted)).getTime();
    // const jDate = (new Date(jAccepted)).getTime();
    // return iDate - jDate;
  });

  const unsortNewReadyOrders = newAllOrders.filter((order) => {
    const { preparationStatus } = order;
    return preparationStatus === ORDER_STATE.READY;
  });
  const readyOrders = unsortNewReadyOrders.sort((i, j) => {
    const { fulfillment: ifull = {} } = i;
    const { fulfillment: jfull = {} } = j;
    const { fulfilledAt: iFulfilled } = ifull;
    const { fulfilledAt: jFulfilled } = jfull;
    const iDate = (new Date(iFulfilled)).getTime();
    const jDate = (new Date(jFulfilled)).getTime();
    return iDate - jDate;
  });

  const archivedOrders = newAllOrders.filter((order) => {
    const { preparationStatus } = order;
    return preparationStatus === ORDER_STATE.ARCHIVED;
  });

  return [futureOrders, newOrders, progressOrders, readyOrders, archivedOrders];
};
