import { createAsyncThunk } from '@reduxjs/toolkit';
import urqlClient from 'api/urqlClient';
import { setAuthorized } from 'slices/auth';

export const buildAsyncAction = (actionName, asyncFunc) => createAsyncThunk(actionName, async (payload, thunkAPI) => {
  try {
    return await asyncFunc(payload, thunkAPI);
  }
  catch (err) {
    return thunkAPI.rejectWithValue(err);
  }
});

export const handleResult = (result, dispatch) => {
  const { error = {} } = result;
  const { response = {} } = error;
  if (response.status === 401) {
    if (dispatch) {
      dispatch(setAuthorized(false));
    }
  }
};

const buildQueryExecutor = (gqlQuery, variables, onResult) => async (payload, thunkAPI) => {
  const { getState, dispatch } = thunkAPI;
  const vars = variables ? variables(payload) : {};
  const result = await urqlClient.query(gqlQuery, vars).toPromise();
  handleResult(result, dispatch);
  if (onResult) {
    return onResult({
      result, payload, getState, dispatch,
    });
  }
  return result;
};

const buildMutationExecutor = (gqlQuery, variables, onResult) => async (payload, thunkAPI) => {
  const { getState, dispatch } = thunkAPI;
  const vars = variables ? variables(payload) : {};
  const result = await urqlClient.mutation(gqlQuery, vars).toPromise();
  handleResult(result, dispatch);
  if (onResult) {
    return onResult({
      result, payload, getState, dispatch,
    });
  }
  return result;
};

export const buildGqlQueryAction = ({
  actionName, query, variables, onResult,
}) => buildAsyncAction(actionName, buildQueryExecutor(query, variables, onResult));

export const buildGqlMutationAction = ({
  actionName, query, variables, onResult,
}) => buildAsyncAction(actionName, buildMutationExecutor(query, variables, onResult));
