import { createSlice } from '@reduxjs/toolkit';

const NAME = 'waitlistPartyDialog';

const initialState = {
  visible: false,
  entity: null,
};

const slice = createSlice({
  name: NAME,
  initialState,
  reducers: {
    showWaitlistPartyDialog: (state, action) => {
      const { payload = {} } = action;
      const { entity } = payload;
      state.entity = entity;
      state.visible = true;
    },
    hideWaitlistPartyDialog: (state) => {
      state.entity = null;
      state.visible = false;
    },
  },
});

export const { showWaitlistPartyDialog, hideWaitlistPartyDialog } = slice.actions;

export const selectWaitlistPartyDialogVisible = (state) => state[NAME].visible;
export const selectWaitlistPartyDialogEntity = (state) => state[NAME].entity;

export default slice.reducer;
