import { useState, useEffect } from 'react';
import { Dimensions } from 'react-native';
import DimensionContext from 'components/DimensionObserver/Context';

const DimensionObserver = (props) => {
  const [dimensions, setDimensions] = useState({});

  useEffect(() => {
    const dims = Dimensions.get('window');
    setDimensions(dims);
    Dimensions.addEventListener('change', (evt) => {
      const { window } = evt;
      setDimensions(window);
    });
  }, []);

  return (
    <DimensionContext.Provider value={{ dimensions }} {...props} />
  );
};

export default DimensionObserver;
