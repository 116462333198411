import { utcToZonedTime } from 'date-fns-tz';

export const convertToZoned = (date, timezone) => {
  const time = utcToZonedTime(date, timezone);
  return time;
};

export const formatDate = (dateObj) => {
  const time = dateObj.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
  return time;
};

export const addInterval = (date, minutes) => {
  if (date) {
    const dateObj = new Date(date);
    const newObj = new Date(dateObj.getTime() + minutes * 60000);
    return formatDate(newObj);
  }
  return null;
};

export const formatMinutes = (i) => {
  const minutes = i % 60;
  const thours = (i - minutes) / 60;
  const hours = thours % 24;
  const tdays = (thours - hours) / 24;
  const days = tdays % 7;
  const weeks = (tdays - days) / 7;

  const dayText = days ? `${days}d ` : '';
  const minuteText = minutes ? `${minutes}min` : '';
  const hourText = hours ? `${hours}hr ` : '';
  const weekText = weeks ? `${weeks}w ` : '';

  let title = '';

  if (weeks) {
    title = `${weekText}${dayText}`;
  }
  else if (days) {
    title = `${dayText}${hourText}`;
  }
  else {
    title = `${hourText}${minuteText}`;
  }

  return {
    days, hours, minutes, title,
  };
};

export const toMinutes = (seconds) => Math.round(seconds / 60);

export const addMinutes = (sourceDate, addonMinutes) => {
  const millis = sourceDate.getTime() + addonMinutes * 60 * 1000;
  return new Date(millis);
};
