import {
  configureStore,
  combineReducers,
} from '@reduxjs/toolkit';
// import logger from 'redux-logger';
import { createWrapper } from 'next-redux-wrapper';

import account from 'slices/account';
import auth from 'slices/auth';
import connection from 'slices/connection';
import customers from 'slices/customers';
import menu from 'slices/menu';
import menus from 'slices/menus';
import modifiers from 'slices/modifiers';
import modifierGroups from 'slices/modifierGroups';
import nodes from 'slices/nodes';
import misc from 'slices/misc';
import optimisticDialog from 'slices/optimisticDialog';
import order from 'slices/order';
import orderType from 'slices/orderType';
import orders from 'slices/orders';
import party from 'slices/party';
import preptime from 'slices/preptime';
import sidebar from 'slices/sidebar';
import stress from 'slices/stress';
import timer from 'slices/timer';
import waitlistPartyDialog from 'slices/waitlistPartyDialog';
import waitlistSetting from 'slices/waitlistSetting';
import waitlistTimeDialog from 'slices/waitlistTimeDialog';
import waitlists from 'slices/waitlists';
// import apiListenerMiddleware from './apiListenerMiddleware';

const rootReducer = combineReducers({
  account,
  auth,
  connection,
  customers,
  menu,
  menus,
  modifiers,
  modifierGroups,
  misc,
  nodes,
  optimisticDialog,
  order,
  orderType,
  orders,
  party,
  preptime,
  sidebar,
  stress,
  timer,
  waitlistPartyDialog,
  waitlistTimeDialog,
  waitlists,
  waitlistSetting,
});

const makeStore = (/* context */) => configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(), // .concat(apiListenerMiddleware.middleware),
  // middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
  devTools: process.env.NODE_ENV !== 'production',
});

// const store = configureStore({ reducer: rootReducer })
// export default store

// export an assembled wrapper
export const wrapper = createWrapper(makeStore, { debug: true });
