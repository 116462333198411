import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { update as updatePickupSetting } from 'api/pickupSettings';
import { update as updateDeliveryZone } from 'api/zones';
import { fetchMenus, selectCurrentLocationId } from 'slices';

const NAME = 'preptime';

const initialState = {
  dialogDefaultTime: 20,
  dialog: false,
  dialogType: null,
  dialogMenuId: null,
  dialogPickupSettingId: null,
  dialogDeliverySettingId: null,
  dialogDeliveryZoneId: null,
  dialogTimestep: 15,
  dialogValue: 15,
  menus: {},
};

export const preptimeDialogClose = createAsyncThunk(
  `${NAME}/dialogOpen`,
  async (payload = {}, thunkAPI) => {
    const { getState, dispatch } = thunkAPI;
    const state = getState()[NAME];
    const { value } = payload;
    // Handle cancel dialog
    if (!value) {
      return state.menus;
    }
    try {
      // const menuId = state.dialogMenuId;
      const pickupSettingId = state.dialogPickupSettingId;
      const deliverySettingId = state.dialogDeliverySettingId;
      const deliveryZoneId = state.dialogDeliveryZoneId;
      // const { [menuId]: menu = {} } = state.menus;
      // const preptime = state.dialogType==='delivery' ? []: value;
      // const nMenu = { ...menu, [state.dialogType]: value };
      // const nMenus = { ...state.menus, [menuId]: nMenu };

      // update pickupsetting time
      if (pickupSettingId && pickupSettingId !== null) {
        const locId = selectCurrentLocationId(getState());
        await updatePickupSetting({ id: pickupSettingId, locationId: locId, dispatch })({ same_day_interval: value });
      }

      if (deliveryZoneId && deliveryZoneId !== null) {
        const locId = selectCurrentLocationId(getState());
        await updateDeliveryZone({
          id: deliveryZoneId, locationId: locId, deliverySettingId, dispatch,
        })({ same_day_interval: value });
      }

      dispatch(fetchMenus());

      return {};
      // return nMenus;
    }
    catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const preptimeUpdateMenus = createAsyncThunk(
  `${NAME}/updateMenus`,
  async (menus, thunkAPI) => {
    const { dispatch, getState } = thunkAPI;
    const oldMenus = getState()[NAME].menus;
    try {
      const preptimes = menus.reduce((prev, curr) => {
        const { id, pickupSettings = [], deliverySettings = [] } = curr;
        const { 0: firstPickup = {} } = pickupSettings;
        const { 0: firstDelivery = {} } = deliverySettings;
        const { zones = [] } = firstDelivery;
        const { sameDayInterval: pickupInterval = 20 } = firstPickup;
        const { [id]: oldMenu = {} } = prev;
        const newMenu = { ...oldMenu, pickup: pickupInterval, delivery: zones };
        return { ...prev, [id]: newMenu };
      }, oldMenus);
      // dispatch sync action for update
      dispatch(setPreptimeMenus(preptimes));
    }
    catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

const slice = createSlice({
  name: NAME,
  initialState,

  reducers: {
    setPreptimeMenus: (state, action) => {
      state.menus = action.payload;
    },
    setPreptimeDialogOpen: (state, action) => {
      const {
        type, id, pickupSettingId, timestep, value, deliveryZoneId, deliverySettingId,
      } = action.payload;
      state.dialog = true;
      state.dialogType = type;
      state.dialogMenuId = id;
      state.dialogPickupSettingId = pickupSettingId;
      state.dialogDeliveryZoneId = deliveryZoneId;
      state.dialogDeliverySettingId = deliverySettingId;
      state.dialogTimestep = timestep;
      state.dialogValue = value;
    },
  },

  extraReducers: {
    [preptimeDialogClose.fulfilled]: (state/* , action */) => {
      // state.menus = action.payload;
      state.dialog = false;
      state.dialogType = null;
      state.dialogMenuId = null;
      state.dialogPickupSettingId = null;
      state.dialogTimestep = 15;
    },
  },
});

export const {
  setPreptimeMenus,
  setPreptimeDialogOpen,
  setDeliveryTime,
  setPickupTime,
  setTableTime,
} = slice.actions;

export const selectPreptimeDialogOpen = (state) => state[NAME].dialog;
export const selectPreptimeDialogType = (state) => state[NAME].dialogType;
export const selectPreptimeDialogValue = (state) => state[NAME].dialogValue;
export const selectPreptimeDialogTimestep = (state) => state[NAME].dialogTimestep;
export const selectPreptimeMenus = (state) => state[NAME].menus;

export const selectDialogDefaultTime = (state) => {
  const { [NAME]: substate } = state;
  if (substate.dialogType !== null && substate.dialogMenuId !== null) {
    const menuId = substate.dialogMenuId;
    const type = substate.dialogType;
    const { [menuId]: menu = {} } = substate.menus;
    const { [type]: time } = menu;
    return time;
  }
  return 20;
};

export default slice.reducer;
