import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit';
import { selectAccount } from 'slices/account';
import { start } from 'api/stress';

const NAME = 'stress';

const initialState = {
  loading: 'idle',
  error: null,
  received: 0,
  lastTrial: 0,
};

export const stressStart = createAsyncThunk(
  'stress/start',
  async (payload, thunkAPI) => {
    try {
      const { getState } = thunkAPI;
      const account = selectAccount(getState());
      const { id } = account;
      await start({ accountId: id });
      return {};
    }
    catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

const slice = createSlice({
  name: NAME,
  initialState,
  reducers: {
    setStressTrial: (state, action) => {
      state.lastTrial = Number(action.payload);
      state.received = current(state).received + 1;
    },
  },
  extraReducers: {
    [stressStart.pending]: (state) => {
      state.error = null;
      state.received = 0;
      state.lastTrial = 0;
      state.loading = true;
    },
    [stressStart.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [stressStart.fulfilled]: (state) => {
      state.loading = false;
      state.error = null;
    },
  },
});

export const { setStressTrial } = slice.actions;

export const selectStress = (state) => state[NAME];

export default slice.reducer;
