import { gql } from 'urql';

export const SHOP_CUSTOMER_FRAGMENT = gql`
  fragment ShopCustomer on ShopCustomer {
    avatarUrl
    email
    firstName
    fullName
    lastName
    phone
  }
`;

export const CUSTOMERS_FIND = gql`
  query($email: String, $phone: String, $limit: Int!, $name: String) {
    customers: customers(limit: $limit, q: {
      emailStart: $email,
      phoneCont: $phone,
      g: {m: "or" firstNameCont:$name lastNameCont:$name }
    }){
      id
      firstName
      lastName
      email
      phone
    }
  }
`;
