import { setAuthorized } from 'slices/auth';
import { buildUrl } from 'api/url';
import camelizeKeys from 'utils/camelizeKeys';

const TOKEN_EXPIRY_THR_MS = 60000;

// export const storage = sessionStorage;
export const storage = () => {
  if (typeof window !== 'undefined') {
    return localStorage;
  }
  return { getItem: () => '' };
};

export const isStateValid = (state) => {
  const { token, expiresAt } = state;
  if (!token) {
    return false;
  }
  if (!expiresAt) {
    return false;
  }

  const now = Date.now();
  const diff = expiresAt - now;
  if (diff > TOKEN_EXPIRY_THR_MS) {
    return true;
  }
  return false;
};

export const isTokenValid = () => {
  const state = getTokenInfo();
  return isStateValid(state);
};

/* export const doTokenTimeoutRefresh = (expiresIn, dispatch) => {
  setTimeout(() => {
    dispatch(freshToken());
  }, (expiresIn * 1000 - TOKEN_EXPIRY_THR_MS));
};
*/

export const getTokenInfo = () => {
  const token = storage().getItem('token');
  const expiresAt = storage().getItem('tokenExpiresAt');
  const refreshToken = storage().getItem('refreshToken');
  return { token, expiresAt, refreshToken };
};

// TODO: useRefresh could be removed
export const tokenizeHeaders = async ({
  headers = {}, useRefresh, preventRefresh, dispatch,
}) => {
  if (!preventRefresh) {
    await getValidToken(dispatch);
  }
  const { token, refreshToken } = getTokenInfo();
  const authHeader = useRefresh ? {} : { Authorization: `Bearer ${useRefresh ? refreshToken : token}` };
  return { ...headers, ...authHeader };
};

export const getValidToken = async (dispatch) => {
  const token = getTokenInfo();
  const isCurrentValid = isTokenValid();
  if (isCurrentValid) {
    return token;
  }
  try {
    const { refreshToken } = token;
    const REFRESH_URL = `oauth/token?grant_type=refresh_token&refresh_token=${refreshToken}`;
    const headers = { 'Content-Type': 'application/json' };
    const response = await fetch(buildUrl(REFRESH_URL), {
      method: 'POST',
      headers,
    });

    if (response.status < 300) {
      const json = await response.json();
      const cJson = camelizeKeys(json);
      if (dispatch) {
        dispatch(setAuthorized(true));
      }
      const {
        token, refreshToken, expiresIn, createdAt,
      } = cJson;
      const expiresAt = new Date(createdAt);
      expiresAt.setSeconds(expiresAt.getSeconds() + expiresIn);
      storage().setItem('token', token);
      storage().setItem('refreshToken', refreshToken);
      storage().setItem('tokenExpiresAt', expiresAt.getTime());
      return cJson;
    }
    if (dispatch) {
      dispatch(setAuthorized(false));
    }
  }
  catch (ex) {
    if (dispatch) {
      dispatch(setAuthorized(false));
    }
  }

  return {};
};
