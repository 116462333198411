import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { index as indexAccounts } from 'api/accounts';
import { fetchMenus } from 'slices/menus';
import { fetchOrders } from 'slices/orders';

const NAME = 'account';

const initialState = {
  loading: 'idle',
  error: null,
  account: { locations: [], shop: {} },
  currentLocationId: null, // selected location
};

export const setCurrentLocationId = createAsyncThunk(
  'account/setCurrentLocationId',
  async (id, thunkAPI) => {
    const { dispatch } = thunkAPI;
    await dispatch(setCurrentLocationIdAtom(id));
    dispatch(fetchMenus());
    dispatch(fetchOrders({ withDialog: true, silent: false, forceDefault: true }));
    return id;
  }
);

export const fetchAccountAndSelectDefault = createAsyncThunk(
  'account/fetchAndSelect',
  async (_, thunkAPI) => {
    try {
      const { dispatch, getState } = thunkAPI;
      await dispatch(fetchAccount());
      // Pre-select location (First or stored in localstorage)
      const locations = selectLocations(getState());
      const { 0: firstLoc = {} } = locations;
      const { id: firstLocId } = firstLoc;
      const stored = Number(localStorage.getItem('LocationID'));
      const hasStored = locations.find((loc) => loc.id === stored);
      const newLocationId = hasStored ? stored : firstLocId;
      await dispatch(setCurrentLocationId(newLocationId));
    }
    catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const fetchAccount = createAsyncThunk(
  'account/getAccount',
  async (_, thunkAPI) => {
    try {
      const { dispatch } = thunkAPI;
      const account = await indexAccounts(dispatch)();
      return account;
    }
    catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

const slice = createSlice({
  name: NAME,
  initialState,
  reducers: {
    setCurrentLocationIdAtom: (state, action) => {
      localStorage.setItem('LocationID', action.payload);
      state.currentLocationId = action.payload;
    },
  },
  extraReducers: {
    [fetchAccount.fulfilled]: (state, action) => {
      const account = action.payload;
      state.account = account;
      state.loading = 'idle';
      state.error = null;
    },
    [fetchAccount.pending]: (state) => {
      state.loading = 'pending';
    },
    [fetchAccount.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = 'idle';
    },
  },

});

export const { setCurrentLocationIdAtom } = slice.actions;

export const selectAccount = (state) => state[NAME].account;
export const selectLocations = (state) => state[NAME].account?.locations;
export const selectAccountShop = (state) => state[NAME].account?.shop;
export const selectCurrentLocationId = (state) => state[NAME].currentLocationId;
export const selectCurrentLocation = (state) => {
  const locId = selectCurrentLocationId(state);
  const all = selectLocations(state);
  const current = all.find((loc) => loc.id === locId);
  return current;
};
export const selectCurrentLocationAutoAccept = (state) => {
  const loc = selectCurrentLocation(state);
  const { autoAcceptOrders = true } = loc || {};
  return autoAcceptOrders;
};

export default slice.reducer;
