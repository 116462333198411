import { createSlice } from '@reduxjs/toolkit';
import {
  CUSTOMERS_FIND,
} from 'api/queries/shop/customer';
import { buildGqlQueryAction } from 'slices/builders';

const NAME = 'customers';

const initialState = {
  loading: false,
  error: null,
  list: [],
};

export const customersFind = buildGqlQueryAction({
  actionName: 'customers/find',
  query: CUSTOMERS_FIND,
  variables: (payload = {}) => {
    const { phone, email, name } = payload;
    return {
      limit: 10, phone, email, name,
    };
  },
});

const slice = createSlice({
  name: NAME,
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(customersFind.fulfilled, (state, action) => {
        const { payload = {} } = action;
        const { data = {} } = payload;
        const { customers = [] } = data;
        state.list = customers;
        state.loading = false;
        state.error = null;
      })
      .addCase(customersFind.pending, (state) => {
        state.loading = true;
      });
  },

});

export const selectCustomersDomain = (state) => state[NAME];
export const selectCustomers = (state) => selectCustomersDomain(state).list;
export const selectCustomersLoading = (state) => selectCustomersDomain(state).loading;

export default slice.reducer;
