import { createSlice } from '@reduxjs/toolkit';
import { selectOrdersDomain } from 'slices';

export const orderTypeActive = 'orderType/active';
export const orderTypeFuture = 'orderType/future';
export const orderTypeDone = 'orderType/done';

const SUBLISTS = {
  [orderTypeActive]: ['newOrders', 'progressOrders', 'readyOrders'],
  [orderTypeFuture]: ['futureOrders'],
  [orderTypeDone]: ['archivedOrders'],
};

const initialState = {
  orderType: orderTypeActive,
};

const slice = createSlice({
  name: 'orderType',
  initialState,
  reducers: {
    setOrderType: (state, action) => {
      state.orderType = action.payload;
    },
  },
});

export const { setOrderType } = slice.actions;

export const selectOrderType = (state) => state.orderType.orderType;
export const selectOrderTypeIsEmpty = (state) => {
  const orderType = selectOrderType(state);
  const { [orderType]: sublistNames } = SUBLISTS;
  const ordersDoman = selectOrdersDomain(state);
  const sublist = sublistNames.map((name) => ordersDoman[name]);
  const isEmpty = sublist.reduce((prev, curr) => !curr.length || prev, false);
  return isEmpty;
};

export default slice.reducer;
