import { tokenizeHeaders } from 'utils/token';
import camelizeKeys from 'utils/camelizeKeys';
import { buildUrl } from 'api/url';
import { setAuthorized } from 'slices/auth';

const handleResponseStatus = (response, dispatch) => {
  const { status } = response;
  if (status === 401) {
    if (dispatch) {
      dispatch(setAuthorized(false));
    }
  }
  if (status >= 400) {
    throw new Error(status);
  }
};

const defaultHandler = async (response) => {
  const json = await response.json();
  return camelizeKeys(json);
};

export const buildGet = ({ url, auth, dispatch }) => async (handler = defaultHandler) => {
  const method = 'GET';
  const headers = await tokenizeHeaders({ dispatch });
  const response = await fetch(buildUrl(url, auth), {
    method,
    headers,
  });
  handleResponseStatus(response, dispatch);
  const result = await handler(response);
  return result;
};

export const buildPost = ({
  url, auth, dispatch, refreshToken = false, formData, preventRefresh = false,
}) => async (body, handler = defaultHandler) => {
  const method = 'POST';
  const defaultHeader = formData ? {} : { 'Content-Type': 'application/json', Accept: 'application/json' };
  const headers = await tokenizeHeaders({
    headers: defaultHeader, refreshToken, preventRefresh, dispatch,
  });
  const response = await fetch(buildUrl(url, auth), {
    method,
    headers,
    body: (body === '' || formData) ? body : JSON.stringify(body),
  });
  handleResponseStatus(response, dispatch);
  const result = await handler(response);
  return result;
};

export const buildPatch = ({ url, auth, dispatch }) => async (body, handler = defaultHandler) => {
  const method = 'PATCH';
  const headers = await tokenizeHeaders({ headers: { 'Content-Type': 'application/json' }, dispatch });
  const response = await fetch(buildUrl(url, auth), {
    method,
    headers,
    body: JSON.stringify(body),
  });
  handleResponseStatus(response, dispatch);
  const result = await handler(response);
  return result;
};
