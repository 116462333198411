import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { index, pause, update } from 'api/menus';
import { selectCurrentLocationId } from 'slices/account';
import { preptimeUpdateMenus } from 'slices/preptime';

const NAME = 'menus';

const initialState = {
  loading: 'idle',
  error: null,
  menus: [],
  menusDialogVisible: false,
};

export const updateMenus = createAsyncThunk(
  'menus/update',
  async (payload, thunkAPI) => {
    try {
      const { id } = payload;
      // const pauseInterval = isPause ? 'rest_of_the_day' : null;
      const { dispatch, getState } = thunkAPI;
      const locId = selectCurrentLocationId(getState());
      const menu = await update({ menuId: id, locationId: locId, dispatch })(payload);
      return menu;
    }
    catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const pauseMenus = createAsyncThunk(
  'menus/pause',
  async (isPause, thunkAPI) => {
    try {
      const pauseInterval = isPause ? 'rest_of_the_day' : null;
      const { getState, dispatch } = thunkAPI;
      const locId = selectCurrentLocationId(getState());
      const menus = await pause({ locationId: locId, dispatch })({ pause_interval: pauseInterval });
      return menus;
    }
    catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const fetchMenus = createAsyncThunk(
  'menus/fetch',
  async (_, thunkAPI) => {
    try {
      const { getState, dispatch } = thunkAPI;
      const locId = selectCurrentLocationId(getState());
      const menus = await index({ locationId: locId, dispatch })();
      dispatch(preptimeUpdateMenus(menus));
      return menus;
    }
    catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

const slice = createSlice({
  name: NAME,
  initialState,
  reducers: {
    setMenusDialogVisible: (state, action) => {
      state.menusDialogVisible = action.payload;
    },
  },
  extraReducers: {
    [pauseMenus.pending]: (state) => {
      state.loading = 'pending';
    },
    [pauseMenus.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = 'idle';
    },
    [pauseMenus.fulfilled]: (state, action) => {
      const menus = action.payload;
      state.menus = menus;
      state.loading = 'idle';
      state.error = null;
    },

    [fetchMenus.fulfilled]: (state, action) => {
      const menus = action.payload;
      state.menus = menus;
      state.loading = 'idle';
      state.error = null;
    },
    [fetchMenus.pending]: (state) => {
      state.loading = 'pending';
    },
    [fetchMenus.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = 'idle';
    },
  },

});

export const { setMenusDialogVisible } = slice.actions;

export const selectMenus = (state) => state[NAME].menus;
export const selectMenusLoading = (state) => state[NAME].loading;
export const selectIsMenusPending = (state) => state[NAME].loading === 'pending';
export const selectOloMenus = (state) => {
  const all = selectMenus(state) || [];
  return all.filter((menu) => {
    const {
      onlineOrdering, menuType, deliverySettingIds, pickupSettingIds, tableSettingIds,
    } = menu;
    const hasSchedule = deliverySettingIds.length > 0 || pickupSettingIds.length > 0 || tableSettingIds.length > 0;
    return onlineOrdering && menuType === 'item' && hasSchedule;
  });
};
export const selectIsOrdering = (state) => {
  const menus = selectMenus(state) || [];
  const isOrdering = menus.filter((menu) => !menu.paused).length > 0;
  return isOrdering;
};
export const selectMenusDialogVisible = (state) => state[NAME].menusDialogVisible;

export default slice.reducer;
