import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { update } from 'api/menus';
import { selectCurrentLocationId } from 'slices/account';
import { fetchMenus } from 'slices/menus';

const NAME = 'menu';

const initialState = {
  loading: 'idle',
  error: null,
  menu: {},
};

export const updateMenu = createAsyncThunk(
  'menu/update',
  async (payload, thunkAPI) => {
    try {
      const { dispatch } = thunkAPI;
      const { id/* , ...rest */ } = payload;
      const { getState } = thunkAPI;
      const locId = selectCurrentLocationId(getState());
      const menu = await update({ menuId: id, locationId: locId, dispatch })(payload);
      dispatch(fetchMenus());
      return menu;
    }
    catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const fetchMenu = createAsyncThunk(
  'menu/fetch',
  async (payload, thunkAPI) => {
    try {
      const { locationId /* id */ } = payload;
      let locId = locationId;
      if (!locId) {
        const { getState } = thunkAPI;
        locId = selectCurrentLocationId(getState());
      }
      const menu = {};
      // const menu = await show(id, locId)();
      return menu;
    }
    catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

const slice = createSlice({
  name: NAME,
  initialState,
  // reducers: {
  //   setCurrentLocationId: (state, action) => {
  //     localStorage.setItem('LocationID', action.payload);
  //     state.currentLocationId = action.payload
  //   },
  // },
  extraReducers: {
    [updateMenu.pending]: (state) => {
      state.loading = 'pending';
    },
    [updateMenu.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = 'idle';
    },
    [updateMenu.fulfilled]: (state, action) => {
      const menu = action.payload;
      state.menu = menu;
      state.loading = 'idle';
      state.error = null;
    },
    [fetchMenu.fulfilled]: (state, action) => {
      const menu = action.payload;
      state.menu = menu;
      state.loading = 'idle';
      state.error = null;
    },
    [fetchMenu.pending]: (state) => {
      state.loading = 'pending';
    },
    [fetchMenu.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = 'idle';
    },
  },

});

// export const { setCurrentLocationId } = slice.actions

export const selectMenu = (state) => state[NAME].menu;
export const selectMenuLoading = (state) => state[NAME].loading;
export const selectIsMenuPending = (state) => state[NAME].loading === 'pending';

export default slice.reducer;
