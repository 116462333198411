import { createSlice, isPending, isRejected } from '@reduxjs/toolkit';
import {
  WAITLIST_SETTING_FETCH,
  WAITLIST_SETTING_UPDATE,
} from 'api/queries';
import { buildGqlQueryAction, buildGqlMutationAction } from 'slices/builders';

const NAME = 'waitlistSetting';

const initialState = {
  error: null,
  loading: false,
  waitMinutes: 10,
};

export const waitlistSettingFetch = buildGqlQueryAction({
  actionName: 'waitlistSetting/fetch',
  query: WAITLIST_SETTING_FETCH,
});

export const waitlistSettingUpdate = buildGqlMutationAction({
  actionName: 'waitlistSetting/update',
  query: WAITLIST_SETTING_UPDATE,
  variables: (payload) => ({ input: payload }),
});

const isActionPending = isPending(waitlistSettingFetch, waitlistSettingUpdate);
const isActionRejected = isRejected(waitlistSettingFetch, waitlistSettingUpdate);

const slice = createSlice({
  name: NAME,
  initialState,

  extraReducers: (builder) => {
    builder
      .addCase(waitlistSettingFetch.fulfilled, (state, action) => {
        state.loading = false;
        const { payload } = action;
        const { data = {} } = payload;
        if (data) {
          const { setting = {} } = data;
          const { waitMinutes } = setting;
          state.waitMinutes = waitMinutes;
          state.error = null;
        }
      })
      .addCase(waitlistSettingUpdate.fulfilled, (state, action) => {
        const { payload } = action;
        const { data = {} } = payload;
        const { waitlistSetting = {} } = data;
        const { update = {} } = waitlistSetting;
        const { waitMinutes } = update;
        state.waitMinutes = waitMinutes;
        state.loading = false;
        state.error = null;
      })
      .addMatcher(isActionPending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addMatcher(isActionRejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      });
  },

});

export const selectWaitlistSettingDomain = (state) => state[NAME];
export const selectWaitlistSettingLoading = (state) => selectWaitlistSettingDomain(state).loading;
export const selectWaitlistSettingWaitMinutes = (state) => selectWaitlistSettingDomain(state).waitMinutes;

export default slice.reducer;
