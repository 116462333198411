import { buildGet, buildPost } from 'api/builders';

export const index = ({ locationId, dispatch }) => buildGet({
  url: `sign_up/api/shop/orders/active?q%5Bs%5D=created_at%20desc&location_id=${locationId}`,
  dispatch,
});

export const show = ({ id, locationId, dispatch }) => buildGet({
  url: `sign_up/api/shop/orders/${id}?location_id=${locationId}`,
  dispatch,
})();

export const preparationStatusUpdate = ({ id, locationId, dispatch }) => buildPost({
  url: `sign_up/api/shop/orders/${id}/preparation_status_update?location_id=${locationId}`,
  dispatch,
});

export const print = ({ id, locationId, dispatch }) => buildPost({
  url: `sign_up/api/shop/orders/${id}/print?location_id=${locationId}`,
  dispatch,
});

export const createDelivery = ({ id, locationId, dispatch }) => buildPost({
  // url: `sign_up/api/shop/orders/${id}/create_delivery?location_id=${locationId}`,
  url: `dashboard/api/v2/shop/orders/${id}/create_delivery?location_id=${locationId}`,
  dispatch,
});
