import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { index } from 'api/modifierGroups';
import { selectCurrentLocationId } from 'slices/account';

const NAME = 'modifierGroups';

const initialState = {
  loading: false,
  error: null,
  modifierGroups: [],
};

export const fetchModifierGroups = createAsyncThunk(
  'modifierGroups/fetch',
  async (_, thunkAPI) => {
    try {
      const { getState, dispatch } = thunkAPI;
      const locId = selectCurrentLocationId(getState());
      const items = await index({ locationId: locId, dispatch })();
      return items;
    }
    catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

const slice = createSlice({
  name: NAME,
  initialState,
  extraReducers: {
    [fetchModifierGroups.pending]: (state) => {
      state.loading = true;
    },
    [fetchModifierGroups.fulfilled]: (state, action) => {
      const modifierGroups = action.payload;
      state.modifierGroups = modifierGroups;
      state.loading = false;
      state.error = null;
    },
    [fetchModifierGroups.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
  },

});

export const selectModifierGroups = (state) => state[NAME].modifierGroups;
export const selectModifierGroupsLoading = (state) => state[NAME].loading;

export default slice.reducer;
