import { gql } from 'urql';
import { SHOP_CUSTOMER_FRAGMENT } from './shop/customer';

export const WAITLIST_PARTY_FRAGMENT = gql`
  fragment WaitlistParty on WaitlistParty {
    id
    status
    source
    cover
    note
    waitMinutes
    createdAt
    notifiedAt
    statusUpdatedAt
    customer {
      ...ShopCustomer
    }
  }
  ${SHOP_CUSTOMER_FRAGMENT}
`;

export const WAITLIST_PARTY_STATUS_UPDATED_FRAGMENT = gql`
  fragment WaitlistPartyStatusUpdated on WaitlistParty {
    id
    status
    notifiedAt
    statusUpdatedAt
  }
`;
export const WAITLIST_PARTIES_QUERY = gql`
  query ($past: Boolean){
    parties: waitlistParties(past: $past) {
      ...WaitlistParty
    }
  }
  ${WAITLIST_PARTY_FRAGMENT}
`;

export const WAITLIST_PARTY_CREATE = gql`
  mutation($input: CreateWaitlistPartyInput!) {
    waitlistParty {
      create(input: $input) {
        ...WaitlistParty
      }
    }
  }
  ${WAITLIST_PARTY_FRAGMENT}
`;

export const WAITLIST_PARTY_UPDATE = gql`
  mutation($input: UpdateWaitlistPartyInput!) {
    waitlistParty {
      update(input: $input) {
        ...WaitlistParty
      }
    }
  }
  ${WAITLIST_PARTY_FRAGMENT}
`;

export const WAITLIST_PARTY_NEXT_UP = gql`
  mutation($input: WaitlistPartyNotifyAsNextUpInput!) {
    waitlistParty {
      notifyAsNextUp(input: $input) {
        ...WaitlistPartyStatusUpdated
      }
    }
  }
  ${WAITLIST_PARTY_STATUS_UPDATED_FRAGMENT}
`;

export const WAITLIST_PARTY_READY = gql`
  mutation($input: WaitlistPartyNotifyAsReadyInput!) {
    waitlistParty {
      notifyAsReady(input: $input) {
        ...WaitlistPartyStatusUpdated
      }
    }
  }
  ${WAITLIST_PARTY_STATUS_UPDATED_FRAGMENT}
`;

export const WAITLIST_SEAT_AT_PARTY = gql`
  mutation($input: SeatAtWaitlistPartyInput!) {
    waitlistParty {
      seat(input: $input) {
        ...WaitlistPartyStatusUpdated
      }
    }
  }
  ${WAITLIST_PARTY_STATUS_UPDATED_FRAGMENT}
`;

export const WAITLIST_CANCEL_PARTY = gql`
  mutation($input: CancelWaitlistPartyInput!) {
    waitlistParty {
      cancel(input: $input) {
        ...WaitlistPartyStatusUpdated
      }
    }
  }
  ${WAITLIST_PARTY_STATUS_UPDATED_FRAGMENT}
`;

export const UPDATE_NOTE = gql`
  mutation($id: ID!, $note: String) {
    waitlistParty {
      update(input: { id: $id, note: $note }) {
        id
        note
      }
    }
  }
`;

export const WAITLIST_SETTING_FRAGMENT = gql`
  fragment WaitlistSetting on WaitlistSetting {
    guestableAdding
    handle
    waitMinutes
  }
`;

export const WAITLIST_SETTING_FETCH = gql`
  query {
    setting: waitlistSetting {
      ...WaitlistSetting
    }
  }
  ${WAITLIST_SETTING_FRAGMENT}
`;

export const WAITLIST_SETTING_UPDATE = gql`
  mutation($input: UpdateWaitlistSettingInput!) {
    waitlistSetting {
      update(input: $input) {
        ...WaitlistSetting
      }
    }
  }
  ${WAITLIST_SETTING_FRAGMENT}
`;
